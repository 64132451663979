:root {
  --highlight-color: #00c266;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

html {
  color: #fff;
  background-color: #000;
  width: 100vw;
  overflow-x: hidden;
}

body {
  font: 100%/1.5 "Gill Sans", -apple-system, BlinkMacSystemFont, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid rgba(255,255,255,.5);
}

  a:hover {
    color: var(--highlight-color);
    border-bottom-color: inherit;
  }

p {
  margin-bottom: .75rem;
}

a:hover {
  color: var(--highlight-color);
}

.pad-s {
  padding: .75rem;
}
