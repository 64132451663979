.album {
  /* background-color: #fff; */
  /* color: #000; */
  color: inherit;
  text-decoration: none;
  border: none;
}

.album p {
  margin: 0;
}

.album:hover {
  color: #fff;
  background-color: var(--highlight-color);
}

.album__cover {
  display: block;
  max-width: 100%;
}

.album:hover .album__cover {
  mix-blend-mode: multiply;
}

.album:last-child:hover .album__cover {
  mix-blend-mode: screen;
}
