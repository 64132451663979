.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid__item {
  flex: 1 1 20%;
}

.grid__span2 {
  flex-basis: 2;
}

@media  (max-width: 1200px) {
  .grid__item {
    flex: 1 1 25%;
  }
}

@media  (max-width: 1024px) {
  .grid__item {
    flex: 1 1 33.333%;
  }
}

@media (max-width: 768px) {
  .grid__item {
    flex: 1 1 50%;
  }
}

@media (max-width: 528px) {
  .grid__item {
    flex: 1 1 100%;
  }
}
